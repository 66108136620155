import * as S from './Banner.styles'

export interface BannerProps {
  ctaLabel: string
  ctaUrl: string
  image: string
  tag: string
  text: string
  title: string
}

export const Banner: React.FC<BannerProps> = ({ ctaLabel, ctaUrl, image, tag, text, title, ...props }) => (
  <S.BannerContainer {...props}>
    <S.ImageContainer>
      <S.Image path={image} alt={title} />
    </S.ImageContainer>

    <S.TextSection>
      <S.Tag>{tag}</S.Tag>

      <S.Title>{title}</S.Title>

      <S.Text>{text}</S.Text>

      <S.CTA href={ctaUrl}>{ctaLabel}</S.CTA>
    </S.TextSection>
  </S.BannerContainer>
)
