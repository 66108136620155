import { breakpoints, g, numeralBreakpoints } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import BaseImage from '@pretto/bricks/website/shared/components/Image'

import { addAlpha } from '@pretto/zen/reveal/lib/addAlpha'

import { Banner as BannerComponent } from '@pretto/website/src/templates/home/rows/rowBanner/components/Banner/Banner'

import styled from 'styled-components'

export interface RowBannerProps {
  backgroundImageUrl: string
  ctaLabel: string
  ctaUrl: string
  image: string
  tag: string
  text: string
  title: string
}

export const RowBanner: React.FC<RowBannerProps> = ({ backgroundImageUrl, ...props }) => (
  <RowBannerContainer>
    <Image path={backgroundImageUrl} />

    <Banner {...props} />
  </RowBannerContainer>
)

const RowBannerContainer = styled.div`
  ${grid()};
  width: 100%;
  padding: ${g(6)} 0 ${g(7)} 0;
  position: relative;
  overflow: hidden;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    backdrop-filter: blur(1.2px);
    background-color: ${({ theme }) => addAlpha(theme.colors.neutral1, 0.5)};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(7)} 0 ${g(8)}0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(10)} 0 ${g(12)} 0;
  }
`

const Image = styled(BaseImage).attrs({
  options: {
    crop: 'fill',
  },
  sizes: `(min-width: ${breakpoints.laptop}) 40vw, (min-width: ${breakpoints.tablet}) 100vw`,
  srcSetBreakpoints: [
    numeralBreakpoints.mobileS,
    numeralBreakpoints.mobileM,
    numeralBreakpoints.mobileL,
    numeralBreakpoints.tablet,
    numeralBreakpoints.laptop,
    numeralBreakpoints.desktop,
  ],
})`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  height: 100%;
  transform: translateY(-50%) translateX(-50%);

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: 100%;
    height: unset;
    margin-top: auto;
    margin-left: auto;
  }
`

const Banner = styled(BannerComponent)`
  ${column([2, 4])};
  z-index: 3;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([3, 10])};
  }
`
