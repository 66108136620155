import { breakpoints, g, numeralBreakpoints } from '@pretto/bricks/components/layout'
import BaseImage from '@pretto/bricks/website/shared/components/Image'
import Link from '@pretto/bricks/website/utility/Link'

import styled from 'styled-components'

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${g(3)};
  border-radius: ${g(1)};
  background-color: ${({ theme }) => theme.colors.white};
  gap: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    gap: ${g(5)};
  }
`

export const ImageContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex: 1;
  }
`

export const Image = styled(BaseImage).attrs({
  options: {
    aspectRatio: '326:208',
    crop: 'fill',
  },
  sizes: `(min-width: ${breakpoints.mobileL}) 600px, 416px`,
  srcSetBreakpoints: [
    numeralBreakpoints.mobileS,
    numeralBreakpoints.mobileM,
    numeralBreakpoints.mobileL,
    numeralBreakpoints.tablet,
    numeralBreakpoints.laptop,
    numeralBreakpoints.desktop,
  ],
})`
  display: block;
  width: 100%;
  object-fit: contain;
  aspect-ratio: 326/208;
`

export const TextSection = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex: 1;
  }
`

export const Tag = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ theme }) => theme.typos.heading6};
    display: block;
    border-radius: ${g(1, -4)};
    padding: ${g(1)} ${g(2)};
    background-color: ${({ theme }) => theme.colors.neutral1};
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: ${g(2)};
  }
`

export const Title = styled.div`
  ${({ theme }) => theme.typos.heading5};
  margin-bottom: ${g(1)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ theme }) => theme.typos.heading4};
    margin-bottom: ${g(3)};
  }
`

export const Text = styled.div`
  ${({ theme }) => theme.typos.body4};
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: ${g(3)};
  }
`

export const CTA = styled(Link)`
  ${({ theme }) => theme.typos.body4Underline};
  cursor: pointer;
`
